import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import ru from 'vuetify/lib/locale/ru'
import { isDark } from '@/utils/theme'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: isDark(),
    themes: {
      light: {
        primary: '#ff9000',
        accent: '#e11e1e',
        secondary: '#607d8b',
        info: '#5bc0de',
        warning: '#f0ad4e',
        error: '#d9534f',
        success: '#5cb85c'
      },
      dark: {
        primary: '#ff9000',
        accent: '#e11e1e',
        secondary: '#242d32',
        info: '#26a69a',
        warning: '#ffc107',
        error: '#dd2c00',
        success: '#00e676'
      }
    }
  },
  lang: {
    locales: { ru },
    current: 'ru'
  }
})
