import axios from 'axios'

export default {
  namespaced: true,

  state: () => ({
    loading: false,
    token: localStorage.getItem('token') || '',
    user: {},
    redirectUrl: null
  }),

  getters: {
    isLoggedIn: state => !!state.token,
    roles: state => (state.user || {}).roles || []
  },

  mutations: {
    SET_LOADING (state, value = true) {
      state.loading = !!value
    },
    SET_TOKEN (state, token) {
      state.token = token || null
      if (state.token) {
        localStorage.setItem('token', token)
        axios.defaults.headers.common.Authorization = `Bearer ${token}`
      } else {
        localStorage.removeItem('token')
        delete axios.defaults.headers.common.Authorization
      }
    },
    SET_USER (state, user) {
      state.user = user
    },
    SET_REDIRECT_URL (state, url) {
      state.redirectUrl = url || null
    }
  },

  actions: {
    validateRole ({ getters }, role) {
      return new Promise((resolve) => {
        const check = !role || getters.roles.includes(role)
        resolve(check)
      })
    },
    afterLoginRedirect ({ commit }, url) {
      commit('SET_REDIRECT_URL', url)
    },
    login ({ dispatch, commit, state }, { username, password } = {}) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true)
        axios({
          url: '/api/auth/login',
          data: {
            username,
            password
          },
          method: 'POST'
        }).then(({ data }) => {
          if (data.status !== 200) {
            throw new Error(data.message || 'Возникла непредвиденная ошибка')
          }
          commit('SET_TOKEN', data.token || '')
          commit('SET_USER', data.user || null)
          resolve(true)
        }).catch(err => {
          dispatch('logout')
          reject(err)
        }).finally(() => {
          commit('SET_LOADING', false)
        })
      })
    },
    user ({ dispatch, commit }) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true)
        axios({
          url: '/api/auth/user',
          method: 'GET'
        }).then(({ data }) => {
          if (data.status !== 200) {
            throw new Error(data.message || 'Возникла непредвиденная ошибка')
          }
          commit('SET_USER', data.user || null)
          resolve(data.user || null)
        }).catch(() => {
          dispatch('logout')
          resolve(null)
        }).finally(() => {
          commit('SET_LOADING', false)
        })
      })
    },
    logout ({ commit }) {
      return new Promise((resolve) => {
        commit('SET_TOKEN', null)
        commit('SET_USER', null)
        resolve(true)
      })
    }
  }
}
