<template>
  <v-tabs
    v-if="!list"
    ref="mainMenu"
    class="nav-tabs"
    background-color="transparent"
    color="primary"
    :right="right"
  >
    <template v-for="link in links">
      <v-tab
        v-if="!link.role || roles.includes(link.role)"
        :key="link.name"
        :to="link.path"
        exact
      >
        {{ link.name }}
      </v-tab>
    </template>
  </v-tabs>
  <v-list
    v-else
    :dense="dense"
  >
    <template v-for="link in links">
      <v-list-item
        v-if="!link.role || roles.includes(link.role)"
        :key="link.name"
        :to="link.path"
        exact
      >
        {{ link.name }}
      </v-list-item>
    </template>
  </v-list>
</template>

<script>
export default {
  name: 'MainMenu',
  props: {
    list: {
      type: Boolean,
      default: false
    },
    right: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    scrollOffset: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    links: [
      {
        path: '/',
        name: 'Главная'
      },
      {
        path: '/info',
        name: 'Информация'
      },
      {
        path: '/tests',
        name: 'Тестирование'
      },
      {
        path: '/reviews',
        name: 'Отзывы'
      },
      {
        path: '/map',
        name: 'Карта перспектив'
      },
      {
        path: '/project',
        name: 'Профпроект',
        role: 'project'
      }
    ]
  }),
  computed: {
    roles () {
      return this.$store.getters['auth/roles']
    }
  },
  watch: {
    scrollOffset () {
      this.resetTabSlider()
    }
  },
  mounted () {
    setTimeout(() => {
      this.resetTabSlider()
    }, 1000)
  },
  methods: {
    resetTabSlider () {
      if (!this.list && (this.$refs.mainMenu || {}).callSlider) {
        this.$refs.mainMenu.callSlider()
      }
    }
  }
}
</script>

<style scoped>

</style>
