<template>
  <v-dialog
    v-model="visible"
    scrollable
    :persistent="persistent"
    :fullscreen="fullscreen"
    :width="width"
    @keyup.esc="visible = false"
  >
    <template v-if="activator" #activator="activatorData">
      <slot
        name="activator"
        v-bind="activatorData"
      />
    </template>
    <v-card>
      <v-card-title class="d-flex word-wrap--break">
        <h5 style="flex-basis: 95%;">{{ title }}</h5>
        <v-spacer />
        <v-btn
          icon
          @click="visible = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text
        v-if="hideOnClose && !visible"
        class="d-flex align-center justify-center"
        style="min-height: 400px;"
      >
        <v-progress-circular
          indeterminate
          :size="50"
          color="secondary"
        />
      </v-card-text>
      <v-card-text
        v-else
        style="min-height: 400px;"
      >
        <slot />
      </v-card-text>
      <v-card-actions>
        <slot name="buttons" />
        <v-spacer />
        <v-btn
          text
          @click="visible = false"
        >
          Закрыть
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'CommonDialog',
  props: {
    value: {
      type: Boolean,
      default: false,
      required: true
    },
    title: {
      type: String,
      default: 'Просмотр документа'
    },
    large: {
      type: Boolean,
      default: false
    },
    middle: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    hideOnClose: {
      type: Boolean,
      default: true
    },
    activator: {
      type: Boolean,
      default: false
    },
    fullscreen: {
      type: Boolean,
      default: false
    },
    persistent: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    width () {
      if (this.large) return '80%'
      else if (this.middle) return 800
      else if (this.small) return 400
      return 'auto'
    },
    visible: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style scoped>

</style>
