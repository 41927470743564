import Filters from './filters'

export default {
  namespaced: true,

  state: () => ({
    markers: [],
    clusters: []
  }),

  getters: {},

  mutations: {},

  actions: {},

  modules: {
    Filters
  }
}
