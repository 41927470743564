<template>
  <v-app dark>
    <v-navigation-drawer
      v-if="$vuetify.breakpoint.smAndDown"
      v-model="drawer"
      temporary
      fixed
    >
      <main-menu list />
    </v-navigation-drawer>
    <app-bar v-model="drawer" />
    <v-main>
      <v-toolbar
        dense
        :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-2'"
      >
        <template v-if="$store.state.project.areasLoading">
          <v-spacer />
          <v-skeleton-loader type="chip" />
          <v-spacer />
        </template>
        <v-tabs
          v-else
          v-model="areaIndex"
          fixed-tabs
        >
          <v-tab
            v-for="area in areas"
            :key="area.id"
            v-text="area.name"
          />
        </v-tabs>
      </v-toolbar>
      <v-container class="mb-12">
        <v-row>
          <router-view />
        </v-row>
      </v-container>
    </v-main>
    <app-footer />
  </v-app>
</template>

<script>
import scrollable from '@/mixins/scrollable'
import AppBar from '@/components/App/AppBar'
import MainMenu from '@/components/MainMenu'
import AppFooter from '@/components/App/AppFooter'

export default {
  name: 'ProjectLayout',
  components: { AppFooter, MainMenu, AppBar },
  mixins: [scrollable],
  data: () => ({
    drawer: false
  }),
  computed: {
    areaIndex: {
      get () {
        return this.$store.state.project.areaIndex || 0
      },
      set (val) {
        this.$store.dispatch('project/areaIndex', +(val || 0))
      }
    },
    areas () {
      return this.$store.state.project.areas || []
    }
  },
  async created () {
    await this.$store.dispatch('project/areas')
    this.loadData()
  },
  watch: {
    areaIndex () {
      this.loadData()
    }
  },
  methods: {
    loadData () {
      this.$store.dispatch('project/blocks')
    }
  }
}
</script>

<style>
</style>
