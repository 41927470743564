export const getTheme = () => {
  if (!localStorage.getItem('theme')) {
    localStorage.setItem('theme', 'light')
  }
  return localStorage.getItem('theme')
}

export const setTheme = value => {
  localStorage.setItem('theme', value || 'light')
}

export const isDarkSystem = () => window.matchMedia('(prefers-color-scheme: dark)').matches

export const isDark = () => {
  const current = getTheme()
  return current === 'dark' || (current === 'system' && isDarkSystem())
}

export const systemThemeChangeListener = (callback, remove = false) => {
  const listener = e => {
    if (getTheme() === 'system') {
      callback(e.matches)
    }
  }
  const matchMedia = window.matchMedia('(prefers-color-scheme: dark)')
  if (!remove) {
    matchMedia.addEventListener('change', listener)
  } else {
    matchMedia.removeEventListener('change', listener)
  }
}
