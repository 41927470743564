<template>
  <v-footer
    padless
  >
      <v-card
        flat
        tile
        dark
        class="secondary"
        style="width: 100%;"
      >
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="10" offset-sm="1" md="8" offset-md="2">
                <v-row>
                  <v-col cols="12" sm="7" md="8" lg="9" class="px-2">
                    <h5 class="text-h5 mb-3">«Профперспектива»</h5>
                    <p>
                      Авторский интерактивный проект, в рамках авторского проекта «МАРШРУТ УСПЕХА»,
                      занявшего в 2015 году первое место во Всероссийском конкурсе новых идей и
                      технологий в области профориентации (ФИРО, Москва).
                    </p>
                  </v-col>
                  <v-col cols="12" sm="5" md="4" lg="3" class="px-2">
                    <h5 class="text-h5 mb-3">Контакты</h5>
                    <div class="mb-3">
                      <a class="text-decoration-none white--text text-body-1" href="tel://89618032160">8 (961) 803-21-60</a>
                    </div>
                    <div class="mb-3">
                      <a class="text-decoration-none primary--text text-body-1" href="mailto:to@profperspektiva.ru">to@profperspektiva.ru</a>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-text class="secondary darken-1 text-center">
          &copy;
          <a class="primary--text text-decoration-none" href="https://profperspektiva.ru">«Профперспектива»</a>
          {{ new Date().getFullYear() }}
        </v-card-text>
      </v-card>
  </v-footer>
</template>

<script>
export default {
  name: 'AppFooter'
}
</script>

<style scoped>

</style>
