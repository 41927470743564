import Vue from 'vue'
import Vuex from 'vuex'
import Auth from './auth'
import Map from './map/index'
import Project from './project/index'

Vue.use(Vuex)

export default new Vuex.Store({
  state: () => ({}),

  mutations: {
    SET_LOADING (state, val) {
      state.loading = val || false
    }
  },

  actions: {},

  modules: {
    map: Map,
    auth: Auth,
    project: Project
  }
})
