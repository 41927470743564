import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    meta: {
      title: 'Главная',
      auth: false,
      role: null,
      layout: 'main'
    }
  },
  {
    path: '/info',
    name: 'Info',
    component: () => import(/* webpackChunkName: "info" */ '../views/Info.vue'),
    meta: {
      title: 'Информация',
      auth: false,
      role: null
    }
  },
  {
    path: '/tests',
    name: 'Tests',
    component: () => import(/* webpackChunkName: "tests" */ '../views/Tests.vue'),
    meta: {
      title: 'Тестирование',
      auth: false,
      role: null
    }
  },
  {
    path: '/reviews',
    name: 'Reviews',
    component: () => import(/* webpackChunkName: "reviews" */ '../views/Reviews.vue'),
    meta: {
      title: 'Отзывы',
      auth: false,
      role: null
    }
  },

  {
    path: '/map',
    name: 'Map',
    component: () => import(/* webpackChunkName: "map" */ '../views/map/Index.vue'),
    meta: {
      title: 'Карта перспектив',
      auth: false,
      role: null,
      layout: 'map'
    },
    children: [
      {
        path: 'org/:orgId',
        name: 'MapOrg',
        component: () => import(/* webpackChunkName: "map" */ '../views/map/Org.vue'),
        meta: {
          title: 'Карта перспектив',
          auth: false,
          role: null,
          layout: 'map'
        }
      },
      {
        path: 'org/:orgId/prof/:profId',
        name: 'MapOrgProf1',
        component: () => import(/* webpackChunkName: "map" */ '../views/map/Prof.vue'),
        meta: {
          title: 'Карта перспектив',
          auth: false,
          role: null,
          layout: 'map'
        }
      }
    ]
  },

  {
    path: '/project',
    component: () => import(/* webpackChunkName: "projectMain" */ '../views/project/Index.vue'),
    meta: {
      title: 'Профпроект',
      auth: true,
      role: 'project',
      layout: 'project'
    },
    children: [
      {
        path: '/',
        name: 'ProjectMain',
        component: () => import(/* webpackChunkName: "projectAbout" */ '../views/project/Main.vue'),
        meta: {
          title: 'Профпроект',
          auth: true,
          role: 'project',
          layout: 'project'
        }
      },
      {
        path: 'about',
        name: 'ProjectAbout',
        component: () => import(/* webpackChunkName: "projectAbout" */ '../views/project/About.vue'),
        meta: {
          title: 'О нас',
          auth: true,
          role: 'project',
          layout: 'project'
        }
      },
      {
        path: 'bases',
        name: 'ProjectBases',
        component: () => import(/* webpackChunkName: "projectBases" */ '../views/project/Bases.vue'),
        meta: {
          title: 'Площадки реализации',
          auth: true,
          role: 'project',
          layout: 'project'
        }
      },
      {
        path: 'instructions',
        name: 'ProjectInstructions',
        component: () => import(/* webpackChunkName: "instructions" */ '../views/project/Instructions.vue'),
        meta: {
          title: 'Инструкции и письма',
          auth: true,
          role: 'project',
          layout: 'project'
        }
      },
      {
        path: 'realization',
        name: 'ProjectRealization',
        component: () => import(/* webpackChunkName: "realization" */ '../views/project/Realization.vue'),
        meta: {
          title: 'Реализация',
          auth: true,
          role: 'project',
          layout: 'project'
        }
      },
      {
        path: 'program',
        name: 'ProjectProgram',
        component: () => import(/* webpackChunkName: "program" */ '../views/project/Program.vue'),
        meta: {
          title: 'ДОП',
          auth: true,
          role: 'project',
          layout: 'project'
        }
      },
      {
        path: 'mentoring',
        name: 'ProjectMentoring',
        component: () => import(/* webpackChunkName: "mentoring" */ '../views/project/Mentoring.vue'),
        meta: {
          title: 'Наставничество',
          auth: true,
          role: 'project',
          layout: 'project'
        }
      },
      {
        path: 'abc',
        name: 'ProjectABC',
        component: () => import(/* webpackChunkName: "abc" */ '../views/project/ABC.vue'),
        meta: {
          title: 'Азбука профессий',
          auth: true,
          role: 'abc1',
          layout: 'project'
        }
      }
    ]
  },

  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/auth/Login.vue'),
    meta: {
      title: 'Вход на сайт',
      auth: false,
      role: null,
      layout: 'default'
    }
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import(/* webpackChunkName: "logout" */ '../views/auth/Logout.vue'),
    meta: {
      title: 'Выход с сайта',
      auth: false,
      role: null,
      layout: 'default'
    }
  },
  {
    path: '/error',
    name: 'Error',
    component: () => import(/* webpackChunkName: "error" */ '../views/Error.vue'),
    meta: {
      title: 'Нет доступа',
      auth: false,
      role: null
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.auth)) {
    if (!from.name) {
      await store.dispatch('auth/user')
    }
    const loggedIn = store.getters['auth/isLoggedIn']
    const validRole = await store.dispatch('auth/validateRole', to.meta.role)
    if (loggedIn && validRole) {
      next()
      return
    }
    const redirectPath = to.fullPath === '/login' ? '/' : to.fullPath
    await store.dispatch('auth/afterLoginRedirect', redirectPath)
    next('/login')
  } else {
    next()
  }
})

export default router
