<template>
  <v-app dark>
    <v-navigation-drawer
      v-if="$vuetify.breakpoint.smAndDown"
      v-model="drawer"
      temporary
      fixed
    >
      <main-menu list />
    </v-navigation-drawer>
    <app-bar v-model="drawer" />
    <v-main>
      <v-parallax
        src="../assets/img/parallax-default.jpg"
        height="200"
      >
        <v-row
          align="center"
          justify="center"
        >
          <v-col cols="12">
            <h1 class="text-h3 font-weight-light text-center white--text">
              {{ $route.meta.title }}
            </h1>
          </v-col>
        </v-row>
      </v-parallax>
      <v-container>
        <slot />
      </v-container>
    </v-main>
    <app-footer />
  </v-app>
</template>

<script>
import scrollable from '@/mixins/scrollable'
import AppBar from '@/components/App/AppBar'
import MainMenu from '@/components/MainMenu'
import AppFooter from '@/components/App/AppFooter'

export default {
  name: 'DefaultLayout',
  components: { AppFooter, MainMenu, AppBar },
  mixins: [scrollable],
  data: () => ({
    drawer: false
  }),
  mounted () {},
  methods: {}
}
</script>

<style>
</style>
