<template>
  <v-app-bar
    app
    dense
    fixed
    :elevate-on-scroll="elevateOnScroll"
    :dark="dark"
    :class="color"
  >
    <v-app-bar-nav-icon
      v-if="$vuetify.breakpoint.smAndDown"
      @click="drawer = true"
    />
    <v-toolbar-title class="d-flex pl-2">
      <v-img
        src="../../assets/img/logo-32.png"
        width="32px"
        height="32px"
        class="mr-3"
      />
      <span v-if="scrollOffset >= 460" class="logo-title">«Профперспектива»</span>
    </v-toolbar-title>
    <main-menu
      v-if="$vuetify.breakpoint.mdAndUp"
      :right="$vuetify.breakpoint.mdAndUp"
      :scroll-offset="scrollOffset"
    />
    <v-spacer v-else />
    <user-menu />
  </v-app-bar>
</template>

<script>
import UserMenu from '@/components/UserMenu'
import MainMenu from '@/components/MainMenu'

export default {
  name: 'AppBar',
  components: { MainMenu, UserMenu },
  props: {
    scrollOffset: {
      type: Number,
      default: 0
    },
    elevateOnScroll: {
      type: Boolean,
      default: false
    },
    transparentOnScroll: {
      type: Boolean,
      default: false
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    drawer: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    dark () {
      return this.transparentOnScroll ? (this.scrollOffset <= 10) : this.$vuetify.theme.dark
    },
    color () {
      return this.transparentOnScroll ? (this.scrollOffset > 10 ? '' : 'transparent') : ''
    }
  }
}
</script>

<style scoped>
/* Костыль для широкого меню, чтобы текст лого не появлялся */
@media (min-width: 960px) and (max-width: 1150px) {
  .logo-title {
    display: none;
  }
}
</style>
