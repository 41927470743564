<template>
  <v-tooltip bottom>
    <template #activator="{ attrs, on }">
      <abbr v-bind="attrs" v-on="on" title=""><slot name="text" /></abbr>
    </template>
    <slot />
  </v-tooltip>
</template>

<script>
export default {
  name: 'AbbrTooltip'
}
</script>

<style scoped>

</style>
