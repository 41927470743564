<template>
  <v-app dark>
    <v-navigation-drawer
      v-if="$vuetify.breakpoint.smAndDown"
      v-model="drawer"
      temporary
      fixed
    >
      <main-menu list/>
    </v-navigation-drawer>
    <app-bar
      v-model="drawer"
      :scroll-offset="scrollOffset"
      transparent-on-scroll
      elevate-on-scroll
    />
    <v-parallax
      :height="parallaxHeight"
      min-height="100vh"
      src="../assets/img/hero.jpg"
      class="align-center justify-center"
    >
      <v-row
        align="center"
        justify="center"
      >
        <v-col cols="12">
          <h1 class="text-h4 text-sm-h2 text-center primary--text mb-10">
            «Профперспектива»
          </h1>
          <h2 class="text-h6 text-sm-h5 text-center white--text font-weight-light">
            Покажем профессиональные перспективы, поможем выбрать верный маршрут!
          </h2>
        </v-col>
      </v-row>
      <scroll-down-btn
        :scroll-offset="scrollOffset"
        target-id="main-container"
      />
    </v-parallax>

    <v-main
      id="main-container"
      v-scroll="onScroll"
    >
      <v-container>
        <v-row>
          <v-col cols="12" class="my-4">
            <h3 class="text-h3 text-center font-weight-light">Мы предлагаем</h3>
          </v-col>
        </v-row>
        <score-counters-row :scroll-offset="scrollOffset"/>
      </v-container>

      <v-parallax
        src="../assets/img/route1-dark.jpg"
        height="250"
        class="mt-12"
      >
        <v-row
          align="center"
          justify="center"
        >
          <v-col cols="12">
            <h1 class="text-h3 font-weight-light text-center white--text">
              Тестирование
            </h1>
          </v-col>
        </v-row>
      </v-parallax>
      <v-container>
        <v-row>
          <v-col
            cols="12"
            lg="10"
            offset-lg="1"
            xl="10"
            offset-xl="1"
            class="pt-12"
          >
            <p class="text-h5 font-weight-light text-justify">
              Первым шагом рекомендуем Вам пройти профориентационное тестирование,
              предлагаемое многими организациями, в том числе и нами.
              Оно поможет определить профессиональную направленность,
              в случае, если Вы сомневаетесь в своих профессиональных наклонностях.
              Или обратитесь к школьному психологу и, с его помощью,
              выявите свои профессиональные интересы и склонности.
            </p>
            <p class="text-h5 font-weight-light text-justify">
              Определите свой профессиональный тип личности и профиль будущей профессиональной деятельности.
            </p>
            <div class="text-center my-6">
              <v-btn to="/tests" color="primary">Пройти тестирование</v-btn>
            </div>
            <p class="text-h5 font-weight-light text-justify">
              А на странице «Информация» можно найти:
              «Как определить свой проф. кластер?» и
              «Востребованные профессии и специальности в СПб и Лен. области» &ndash; это поможет Вам
              правильно определиться с выбором профессиональных кластеров при переходе ко второму шагу!
            </p>
            <div class="text-center my-6">
              <v-btn text outlined to="/info" color="primary">
                Перейти к информации
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>

      <v-parallax
        src="../assets/img/route2-dark.jpg"
        height="250"
        class="mt-12"
      >
        <v-row
          align="center"
          justify="center"
        >
          <v-col cols="12">
            <h1 class="text-h3 font-weight-light text-center white--text">
              Карта перспектив
            </h1>
          </v-col>
        </v-row>
      </v-parallax>
      <v-container>
        <v-row>
          <v-col
            cols="12"
            lg="10"
            offset-lg="1"
            xl="10"
            offset-xl="1"
            class="pt-12"
          >
            <p class="text-h5 font-weight-light text-justify">
              Итак, Вы определили свою профессиональную направленность. Теперь легко определить основные
              профессиональные кластеры, подходящие Вам (соответствующие рекомендуемому профилю).
            </p>
            <p class="text-h5 font-weight-light text-justify">
              Выберите интересующие Вас кластеры и поработайте с ними на Карте перспектив.
            </p>
            <div class="text-center my-6">
              <v-btn
                color="primary"
                :disabled="loadingClusters"
                :loading="loadingClusters"
                @click="clustersDialog = true"
              >
                Таблица кластеров
              </v-btn>
            </div>
            <div class="text-center my-6">
              <v-btn
                to="/map"
                color="primary"
                class="mx-1"
              >
                Карта перспектив
              </v-btn>
              <v-btn
                color="secondary"
                class="mx-1"
                @click="mapDescDialog = true"
              >
                Описание
              </v-btn>
            </div>
            <common-dialog
              v-model="clustersDialog"
              :persistent="false"
              middle
              title="Профессиональные кластеры"
            >
              <p class="text-h5 font-weight-light text-justify">
                Все профессии и специальности
                <abbr-tooltip>
                  <template #text>СПО</template>
                  <template>Среднего профессионального образования</template>
                </abbr-tooltip>
                и
                <abbr-tooltip>
                  <template #text>ВПО</template>
                  <template>Высшего профессионального образования</template>
                </abbr-tooltip>
                на нашем сайте разделены на 12 групп &ndash; профессиональных кластеров:
              </p>
              <v-list class="mb-4">
                <v-list-item
                  v-for="(cluster, index) in clusters"
                  :key="cluster.code"
                >
                  <v-list-item-avatar
                    :color="cluster.color"
                    v-text="index + 1"
                    class="white--text"
                  />
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="cluster.name"
                      class="font-wrap text-h6 font-weight-light"
                    />
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <p class="text-h5 font-weight-light text-justify">
                Примеры профессий и специальностей, входящих в каждый кластер Вы можете найти на
                <router-link to="/info" target="_blank">странице информации</router-link>.
              </p>
            </common-dialog>
            <common-dialog
              v-model="mapDescDialog"
              :persistent="false"
              middle
              title="Информация о «Карте перспектив»"
            >
              <p class="text-h5 font-weight-light text-justify">
                Чтобы познакомиться с нашим проектом, воспользуйтесь
                <router-link to="/map" target="_blank">демонстрационной версией</router-link>,
                где доступны четыре образовательные организации
                <abbr-tooltip>
                  <template #text>СПО</template>
                  <template>Среднего профессионального образования</template>
                </abbr-tooltip>
                в двух профессиональных кластерах, а также всего две профессии.
              </p>
              <p class="text-h5 font-weight-light text-justify">
                А в полной версии проекта Вас ждёт:
              </p>
              <score-counters-row
                :scroll-offset="200"
                :md="6"
              />
              <p class="text-h5 font-weight-light text-justify">
                А также сайты, адреса, телефоны, email, способы проезда, дни открытых дверей, вступительные
                испытания, сроки обучения!
                Вам ничего не придётся искать самим!
              </p>
              <p class="text-h5 font-weight-light text-justify">
                Вся информация собрана в одном месте, проанализирована, скомпонована, приведена к удобному виду.
                Вам останется лишь сделать свой выбор!
              </p>
              <p class="text-h5 font-weight-light text-justify">
                А сделать это с нашим проектом очень просто!
                А если вдруг что-то покажется сложным или непонятным &ndash; ознакомьтесь с окном помощи,
                нажав на соответствующую кнопку на карте или свяжитесь с нами!
              </p>
            </common-dialog>
          </v-col>
        </v-row>
      </v-container>

      <v-parallax
        src="../assets/img/route3-dark.jpg"
        height="250"
        class="mt-12"
      >
        <v-row
          align="center"
          justify="center"
        >
          <v-col cols="12">
            <h1 class="text-h3 font-weight-light text-center white--text">
              «Профпроект»
            </h1>
          </v-col>
        </v-row>
      </v-parallax>
      <v-container>
        <v-row>
          <v-col
            cols="12"
            lg="10"
            offset-lg="1"
            xl="10"
            offset-xl="1"
            class="pt-12"
          >
            <p class="text-h5 font-weight-light text-justify">
              Общеобразовательным школам предлагаем заказать
              <b>Дополнительную общеразвивающую программу (ДОП) «Профпроект»</b>
              для 8-9 классов &ndash; по 34 часа в год (1 час в неделю) или 68 часов в год (2 часа в неделю).
              Программа успешно реализуется в общеобразовательных школах СПБ и Ленинградской области,
              включена в реестр рекомендованных World Skills «Билет в будущее»
              программ для обучающихся общеобразовательных школ РФ
              Заказав программу Вы получите доступ к учебно-методическому пособию для реализации программы.
              Каждый урок подробно разработан и включает интерактивную часть.
            </p>
            <div class="text-center my-6">
              <v-btn
                color="primary"
                class="mx-1"
                :disabled="loadingDocuments"
                :loading="loadingDocuments"
                @click="projectPresentationDocumentDialog = true"
              >
                Презентация
              </v-btn>
              <v-btn
                color="secondary"
                class="mx-1"
                @click="projectDescDialog = true"
              >
                Описание
              </v-btn>
              <v-btn
                color="primary"
                class="mx-1"
                :disabled="loadingDocuments"
                :loading="loadingDocuments"
                @click="projectInstructionDocumentDialog = true"
              >
                Инструкция
              </v-btn>
            </div>
            <common-dialog
              v-model="projectDescDialog"
              :persistent="false"
              middle
              title="Описание ДОП «Профпроект»"
            >
              <p class="text-h5 font-weight-light text-justify">
                <b>Цель:</b> Создать систему эффективной профориентации в образовательном учреждении,
                способствующей формированию профессионального самоопределения подростков в соответствии с их
                способностями,
                индивидуальными особенностями, с учетом запроса современного общества в востребованных специалистах.
              </p>
              <p class="text-h5 font-weight-light text-justify">
                <b>Особенности:</b> Программа включает 68 академических часов;
                рекомендована учащимся 8-10 классов общеобразовательных школ;
                рекомендует прохождение профориентационного тестирования,
                с целью определения профессиональной направленности личности;
                рекомендует подключение к интерактивной компьютерной программе «Профперспектива»,
                с целью практического выбора профессии и профессиональной образовательной организации для дальнейшего
                обучения избранной профессии.
              </p>
              <p class="text-h5 font-weight-light text-justify">
                Предоставляем подробное методическое пособие (разработанное для педагогов) и три приложения
                <b>(в том числе в Приложении №2 практикумы для дистанционной работы).</b>
                Программа включает промежуточный и итоговый контроль (творческое задание для обучающихся по
                окончательному выбору профессии и профессиональной образовательной организации).
              </p>
            </common-dialog>
            <cloud-document-dialog
              v-model="projectPresentationDocumentDialog"
              :doc-id="(projectPresentation || {}).uniqueId || ''"
              :title="(projectPresentation || {}).name || ''"
              :view-url="viewUrl"
              :download-url="downloadUrl"
              :open-url="openUrl"
              middle
            />
            <cloud-document-dialog
              v-model="projectInstructionDocumentDialog"
              :doc-id="(projectInstruction || {}).uniqueId || ''"
              :title="(projectInstruction || {}).name || ''"
              :view-url="viewUrl"
              :download-url="downloadUrl"
              :open-url="openUrl"
              middle
            />
          </v-col>
        </v-row>
      </v-container>

      <v-parallax
        src="../assets/img/route3-dark.jpg"
        height="250"
        class="mt-12"
      >
        <v-row
          align="center"
          justify="center"
        >
          <v-col cols="12">
            <h1 class="text-h3 font-weight-light text-center white--text">
              «Азбука профессий»
            </h1>
          </v-col>
        </v-row>
      </v-parallax>
      <v-container>
        <v-row>
          <v-col
            cols="12"
            lg="10"
            offset-lg="1"
            xl="10"
            offset-xl="1"
            class="pt-12"
          >
            <p class="text-h5 font-weight-light text-justify">
              Кроме того, общеобразовательным школам предлагаем заказать
              <b>Дополнительную внеурочную программу «Азбука профессий»</b>
              для 1-4 классов &ndash; по 33/34 часа в год (1 час в неделю).
            </p>
            <p class="text-h5 font-weight-light text-justify">
              При заказе программы для обучающихся будут предоставлены
              методические пособия для 1-4 класса (полная разработка занятий) и доступ к видеоматериалам.
            </p>
            <div class="text-center my-6">
              <v-btn
                v-if="projectABCProgram"
                color="primary"
                class="mx-1"
                :disabled="loadingDocuments"
                :loading="loadingDocuments"
                @click="projectABCProgramDocumentDialog = true"
              >
                Целевая программа
              </v-btn>
              <v-btn
                v-if="projectABCPresentation"
                color="secondary"
                class="mx-1"
                :disabled="loadingDocuments"
                :loading="loadingDocuments"
                @click="projectABCPresentationDocumentDialog = true"
              >
                Презентация
              </v-btn>
              <v-btn
                v-if="projectABCExample"
                color="primary"
                class="mx-1"
                :disabled="loadingDocuments"
                :loading="loadingDocuments"
                @click="projectABCExampleDocumentDialog = true"
              >
                Пример занятий
              </v-btn>
            </div>
            <cloud-document-dialog
              v-model="projectABCProgramDocumentDialog"
              :doc-id="(projectABCProgram || {}).uniqueId || ''"
              :title="(projectABCProgram || {}).name || ''"
              :view-url="viewUrl"
              :download-url="downloadUrl"
              :open-url="openUrl"
              middle
            />
            <cloud-document-dialog
              v-model="projectABCPresentationDocumentDialog"
              :doc-id="(projectABCPresentation || {}).uniqueId || ''"
              :title="(projectABCPresentation || {}).name || ''"
              :view-url="viewUrl"
              :download-url="downloadUrl"
              :open-url="openUrl"
              middle
            />
            <cloud-document-dialog
              v-model="projectABCExampleDocumentDialog"
              :doc-id="(projectABCExample || {}).uniqueId || ''"
              :title="(projectABCExample || {}).name || ''"
              :view-url="viewUrl"
              :download-url="downloadUrl"
              :open-url="openUrl"
              middle
            />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <app-footer/>
  </v-app>
</template>

<script>
import scrollable from '@/mixins/scrollable'
import AppBar from '@/components/App/AppBar'
import MainMenu from '@/components/MainMenu'
import AppFooter from '@/components/App/AppFooter'
import ScrollDownBtn from '@/components/ScrollDownBtn'
import CommonDialog from '@/components/CommonDialog'
import AbbrTooltip from '@/components/AbbrTooltip'
import ScoreCountersRow from '@/components/ScoreCountersRow'
import CloudDocumentDialog from '@/components/Document/CloudDocumentDialog'

const windowInner = (window || {}).innerHeight || 800

export default {
  name: 'MainLayout',
  components: {
    CloudDocumentDialog,
    ScoreCountersRow,
    AbbrTooltip,
    CommonDialog,
    ScrollDownBtn,
    AppFooter,
    MainMenu,
    AppBar
  },
  mixins: [scrollable],
  data: () => ({
    drawer: false,
    parallaxHeight: windowInner,
    clustersDialog: false,
    clusters: [],
    mapDescDialog: false,
    projectDescDialog: false,
    projectPresentationDocumentDialog: false,
    projectPresentation: null,
    projectInstructionDocumentDialog: false,
    projectInstruction: null,
    projectABCProgramDocumentDialog: false,
    projectABCProgram: null,
    projectABCPresentationDocumentDialog: false,
    projectABCPresentation: null,
    projectABCExampleDocumentDialog: false,
    projectABCExample: null,
    viewUrl: '',
    downloadUrl: '',
    openUrl: '',
    loadingDocuments: false,
    loadingClusters: false
  }),
  created () {
    this.loadAllClusters()
    this.loadDocuments()
  },
  computed: {
    loggedIn () {
      return this.$store.getters['auth/isLoggedIn']
    }
  },
  methods: {
    loadDocuments () {
      this.loadingDocuments = true
      this.$axios.get('/api/docs/main').then(({ data }) => {
        this.viewUrl = data.url.view
        this.thumbnailUrl = data.url.thumbnail
        this.downloadUrl = data.url.download
        this.openUrl = data.url.open
        this.projectPresentation = data.items.presentation || null
        this.projectInstruction = data.items.instruction || null
        this.projectABCProgram = data.items.abcProgram || null
        this.projectABCPresentation = data.items.abcPresentation || null
        this.projectABCExample = data.items.abcExample || null
      }).catch(() => {
        this.projectPresentation = null
        this.projectInstruction = null
        this.projectABCProgram = null
        this.projectABCPresentation = null
        this.projectABCExample = null
      }).finally(() => {
        this.loadingDocuments = false
      })
    },
    loadAllClusters () {
      this.loadingClusters = true
      this.$axios.get('/api/clusters/all').then(({ data }) => {
        this.clusters = data || []
      }).catch(() => {
        this.clusters = []
      }).finally(() => {
        this.loadingClusters = false
      })
    }
  }
}
</script>

<style>
</style>
