<template>
  <v-row>
    <v-col cols="6" :md="md">
      <score-counter
        :score="12"
        :start="scrollOffset > 180"
        animate
      >
        Профессиональных кластеров!
      </score-counter>
    </v-col>
    <v-col cols="6" :md="md">
      <score-counter
        :score="212"
        :start="scrollOffset > 180"
        animate
      >
        Профессий и специальностей
        <abbr-tooltip>
          <template #text>СПО!</template>
          <template>Среднего профессионального образования</template>
        </abbr-tooltip>
      </score-counter>
    </v-col>
    <v-col cols="6" :md="md">
      <score-counter
        :score="290"
        :start="scrollOffset > 180"
        animate
      >
        Направлений подготовки
        <abbr-tooltip>
          <template #text>ВПО!</template>
          <template>Высшего профессионального образования</template>
        </abbr-tooltip>
      </score-counter>
    </v-col>
    <v-col cols="6" :md="md">
      <score-counter
        :score="1863"
        :start="scrollOffset > 180"
        animate
      >
        Профессии в 184 образовательных
        <abbr-tooltip>
          <template #text>организациях!</template>
          <template>Высшего и среднего профессионального образования</template>
        </abbr-tooltip>
      </score-counter>
    </v-col>
  </v-row>
</template>

<script>
import ScoreCounter from '@/components/ScoreCounter'
import AbbrTooltip from '@/components/AbbrTooltip'

export default {
  name: 'ScoreCountersRow',
  components: { AbbrTooltip, ScoreCounter },
  props: {
    scrollOffset: {
      type: Number,
      default: 0
    },
    md: {
      type: Number,
      default: 3
    }
  }
}
</script>

<style scoped>

</style>
