<template>
  <v-card flat color="transparent">
    <v-card-text class="text-center">
      <v-avatar
        ref="counter"
        :size="100"
        color="primary"
        class="white--text score-counter elevation-3 text-h4"
      >
        {{ animate ? 0 : score }}
      </v-avatar>
    </v-card-text>
    <v-card-text class="text-center text-h5">
      <slot />
    </v-card-text>
  </v-card>
</template>

<script>
import { CountUp } from 'countup.js'

export default {
  name: 'ScoreCounter',
  props: {
    score: {
      type: Number,
      default: 0
    },
    animate: {
      type: Boolean,
      default: false
    },
    start: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    init: false
  }),
  mounted () {
    this.animateCounter()
  },
  watch: {
    start () {
      this.animateCounter()
    }
  },
  methods: {
    animateCounter () {
      if (this.start && !this.init && this.animate) {
        const countUp = new CountUp(this.$refs.counter.$el, this.score, {
          separator: ''
        })
        countUp.start()
        this.init = true
      }
    }
  }
}
</script>

<style scoped>

</style>
