<template>
  <component :is="layout">
    <router-view/>
  </component>
</template>

<script>
import DefaultLayout from '@/layouts/default'
import ProjectLayout from '@/layouts/project'
import MainLayout from '@/layouts/main'
import MapLayout from '@/layouts/map'
import { systemThemeChangeListener } from '@/utils/theme'

export default {
  name: 'App',
  components: {
    DefaultLayout,
    ProjectLayout,
    MainLayout,
    MapLayout
  },
  data: () => ({}),
  computed: {
    layout () {
      return (this.$route.meta.layout || 'default') + '-layout'
    }
  },
  created () {
    this.$axios.interceptors.response.use(undefined, (err) => {
      return new Promise(() => {
        if (err.status === 401 || err.status === 403) {
          this.$store.dispatch('auth/logout')
        }
        throw err
      })
    })
    if (this.$store.state.auth.token) {
      this.$store.dispatch('auth/user', { vm: this })
    }
    systemThemeChangeListener(this.changeTheme)
  },
  beforeDestroy () {
    systemThemeChangeListener(this.changeTheme, true)
  },
  methods: {
    changeTheme (isDark) {
      this.$vuetify.theme.dark = isDark
    }
  }
}
</script>

<style lang="scss">
</style>
