/**
 * Примесь добавляет метод определения отступа скроллинга и соответствующий параметр
 */

export default {
  data: () => ({
    scrollOffset: 0
  }),
  methods: {
    onScroll (event) {
      this.scrollOffset = ((event.target || {}).scrollingElement || {}).scrollTop || 0
    }
  }
}
