<template>
  <v-btn
    v-if="!loggedIn"
    color="success"
    to="/login"
    exact
    text
  >
    <v-icon left>
      mdi-key
    </v-icon>
    Войти
  </v-btn>
  <v-menu
    v-else
    offset-y
    bottom
    :close-on-content-click="false"
    :nudge-width="300"
  >
    <template #activator="{ attrs, on }">
      <v-btn
        icon
        color="primary"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-account</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="text-h5">
        {{ user.name }}
      </v-card-title>
      <v-card-text>
        <v-list dense class="mb-5">
          <template v-for="(item, index) in access">
            <v-list-item :key="item.text">
              <v-list-item-content>
                <v-list-item-title
                  v-text="item.text"
                  :class="item.colorText"
                />
              </v-list-item-content>
              <v-list-item-action>
                <v-icon
                  :color="item.color"
                  v-text="item.icon"
                />
              </v-list-item-action>
            </v-list-item>
            <v-divider
              v-if="index < access.length - 1"
              :key="`${item.text}_div`"
            />
          </template>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="error"
          to="/logout"
          exact
        >
          <v-icon left>
            mdi-exit-to-app
          </v-icon>
          Выйти
        </v-btn>
        <v-spacer />
        <theme-changer />
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import ThemeChanger from '@/components/ThemeChanger'

export default {
  name: 'UserMenu',
  components: { ThemeChanger },
  data: () => ({}),
  computed: {
    loggedIn () {
      return this.$store.getters['auth/isLoggedIn']
    },
    roles () {
      return this.$store.getters['auth/roles']
    },
    access () {
      return [
        {
          text: 'Доступ к Карте перспектив',
          role: 'map'
        },
        {
          text: 'Доступ к ДОП «Профпроект»',
          role: 'project'
        },
        {
          text: 'Доступ к ДОП «Азбука профессий» для 1 класса',
          role: 'abc1'
        },
        {
          text: 'Доступ к ДОП «Азбука профессий» для 2 класса',
          role: 'abc2'
        },
        {
          text: 'Доступ к ДОП «Азбука профессий» для 3 класса',
          role: 'abc3'
        },
        {
          text: 'Доступ к ДОП «Азбука профессий» для 4 класса',
          role: 'abc4'
        }
      ].map(i => {
        const allow = !i.role || this.roles.includes(i.role)
        return {
          ...i,
          color: allow ? 'success' : 'error',
          colorText: allow ? 'success--text' : 'error--text',
          icon: allow ? 'mdi-check' : 'mdi-close',
          iconText: allow ? 'Есть' : 'Нет'
        }
      })
    },
    user () {
      return this.$store.state.auth.user || {}
    }
  }
}
</script>

<style scoped>

</style>
