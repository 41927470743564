<template>
  <div
    class="text-center hero-scroll"
    :class="{ 'animate': scrollOffset <= 500 }"
  >
    <v-btn
      dark
      icon
      x-large
      @click="scrollToTarget"
    >
      <v-icon large>mdi-chevron-down</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'ScrollDownBtn',
  props: {
    scrollOffset: {
      type: Number,
      default: 0
    },
    targetId: {
      type: String,
      default: ''
    }
  },
  methods: {
    scrollToTarget () {
      if (!this.targetId) {
        return
      }
      this.$vuetify.goTo(`#${this.targetId}`, {
        easing: 'easeInOutCubic',
        duration: 700,
        offset: 85
      })
    }
  }
}
</script>

<style scoped lang="scss">
@keyframes animation-scroll-down-opacity-bounce {
  0% {
    opacity: 1;
    bottom: 50px;
  }
  15% {
    opacity: 1;
    bottom: 5px;
  }
  30% {
    opacity: 0.8;
    bottom: 20px;
  }
  45% {
    opacity: 0.5;
    bottom: 5px;
  }
  60% {
    opacity: 0;
    bottom: 13px;
  }
  75% {
    opacity: 0;
    bottom: 5px;
  }
  90% {
    opacity: 0;
    bottom: 7px;
  }
  100% {
    opacity: 0;
    bottom: 50px;
  }
}

.hero-scroll {
  position: absolute;
  bottom: 20px;
  width: 100%;

  &.animate {
    animation: animation-scroll-down-opacity-bounce 2.5s backwards infinite;
  }
}
</style>
