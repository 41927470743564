<template>
  <v-btn-toggle
    v-model="colorTheme"
    rounded
    mandatory
    dense
  >
    <v-btn value="system">
      <v-icon>mdi-theme-light-dark</v-icon>
    </v-btn>

    <v-btn value="dark">
      <v-icon>mdi-weather-night</v-icon>
    </v-btn>

    <v-btn value="light">
      <v-icon>mdi-white-balance-sunny</v-icon>
    </v-btn>
  </v-btn-toggle>
</template>

<script>
import { getTheme, isDarkSystem, setTheme } from '@/utils/theme'

export default {
  name: 'ThemeChanger',
  data: () => ({
    theme: 'light'
  }),
  created () {
    this.theme = getTheme()
  },
  computed: {
    colorTheme: {
      get () {
        return this.theme
      },
      set (val) {
        this.setColorTheme(val)
      }
    }
  },
  methods: {
    changeTheme () {
      if (this.theme === 'light') {
        this.setColorTheme('dark')
      } else if (this.theme === 'dark') {
        this.setColorTheme('system')
      } else {
        this.setColorTheme('light')
      }
    },
    setColorTheme (val) {
      if (val === 'dark') {
        setTheme('dark')
        this.$vuetify.theme.dark = true
      } else if (val === 'system') {
        setTheme('system')
        this.$vuetify.theme.dark = isDarkSystem()
      } else {
        setTheme('light')
        this.$vuetify.theme.dark = false
      }
      this.theme = getTheme()
    }
  }
}
</script>

<style scoped>

</style>
