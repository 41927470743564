<template>
  <v-app dark>
    <v-navigation-drawer
      v-if="$vuetify.breakpoint.smAndDown"
      v-model="drawer"
      temporary
      fixed
    >
      <main-menu list />
    </v-navigation-drawer>
    <app-bar v-model="drawer" />
    <v-main app>
      <slot />
    </v-main>
  </v-app>
</template>

<script>
import scrollable from '@/mixins/scrollable'
import AppBar from '@/components/App/AppBar'
import MainMenu from '@/components/MainMenu'

export default {
  name: 'MapLayout',
  components: { MainMenu, AppBar },
  mixins: [scrollable],
  data: () => ({
    drawer: false
  }),
  mounted () {},
  methods: {}
}
</script>

<style>
</style>
