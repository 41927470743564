<template>
  <common-dialog
    v-model="visible"
    :title="title"
    middle
  >
    <template #default>
      <iframe
        :src="viewUrl + docId"
        :width="752"
        height="600px"
      />
    </template>
    <template #buttons>
      <v-btn
        v-if="downloadUrl"
        text
        :href="downloadUrl + docId"
        target="_blank"
        color="primary"
      >
        <v-icon left>
          mdi-download
        </v-icon>
        Скачать
      </v-btn>
      <v-btn
        v-if="openUrl"
        text
        :href="openUrl + docId"
        target="_blank"
        color="secondary"
      >
        <v-icon left>
          mdi-eye
        </v-icon>
        В окне
      </v-btn>
    </template>
  </common-dialog>
</template>

<script>
import CommonDialog from '@/components/CommonDialog'

export default {
  name: 'CloudDocumentDialog',
  components: { CommonDialog },
  props: {
    value: {
      type: Boolean,
      default: false,
      required: true
    },
    docId: {
      type: String,
      default: '',
      required: true
    },
    viewUrl: {
      type: String,
      default: '',
      required: true
    },
    downloadUrl: {
      type: String,
      default: ''
    },
    openUrl: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: 'Просмотр документа'
    }
  },
  computed: {
    visible: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style scoped>

</style>
