import axios from 'axios'

export default {
  namespaced: true,

  state: () => ({
    areasLoading: false,
    areas: [],
    areaIndex: 0,

    blocksLoading: false,
    blocks: []
  }),

  getters: {
    areaId (state) {
      return (state.areas[state.areaIndex] || {}).id || 0
    },
    areaBlocks (state, getters) {
      return state.blocks[getters.areaId] || []
    }
  },

  mutations: {
    SET_AREAS_LOADING (state, value = true) {
      state.areasLoading = !!value
    },
    SET_AREAS (state, data = []) {
      state.areas = data
    },
    SET_AREA_INDEX (state, id) {
      state.areaIndex = id || 0
      localStorage.setItem('areaIndex', `${id || 0}`)
    },

    SET_BLOCKS_LOADING (state, value = true) {
      state.blocksLoading = !!value
    },
    SET_BLOCKS (state, data = []) {
      state.blocks = data
    }
  },

  actions: {
    areaIndex ({ commit }, id = 0) {
      commit('SET_AREA_INDEX', id)
    },
    areas ({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        commit('SET_AREAS_LOADING', true)
        axios({
          url: '/api/project/areas',
          method: 'GET'
        }).then(({ data }) => {
          if (data.status !== 200) {
            throw new Error(data.message || 'Возникла непредвиденная ошибка')
          }
          const areas = data.areas || []
          const areaIndexFromStorage = +(localStorage.getItem('areaIndex') || 0)
          const areaIndex = areas[areaIndexFromStorage] ? areaIndexFromStorage : 0
          commit('SET_AREAS', areas)
          dispatch('areaIndex', areaIndex)
          resolve(data.areas || [])
        }).catch(err => {
          commit('SET_AREAS')
          reject(err)
        }).finally(() => {
          commit('SET_AREAS_LOADING', false)
        })
      })
    },

    blocks ({ commit }) {
      return new Promise((resolve, reject) => {
        commit('SET_BLOCKS_LOADING', true)
        axios({
          url: '/api/project/blocks',
          method: 'GET'
        }).then(({ data }) => {
          if (data.status !== 200) {
            throw new Error(data.message || 'Возникла непредвиденная ошибка')
          }
          commit('SET_BLOCKS', data.blocks || [])
          resolve(data.areas || [])
        }).catch(err => {
          commit('SET_BLOCKS')
          reject(err)
        }).finally(() => {
          commit('SET_BLOCKS_LOADING', false)
        })
      })
    }
  },

  modules: {}
}
