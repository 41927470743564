
export default {
  namespaced: true,

  state: () => ({
    region: null,
    orgType: null,
    profession: null,
    cluster: null
  }),

  getters: {},

  mutations: {},

  actions: {},

  modules: {}
}
